import "./SharedProductsStyles.css";
import React, { useEffect, useState } from "react";
import Cake from "../../components/productsComponents/ProductCard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import {
  db,
  storage,
  collection,
  getDocs,
  ref,
  getDownloadURL,
} from "../../firebase";

const CakeLadoo = () => {
  const [ladoos, setLadoos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCakes = async () => {
      try {
        const ladooCollection = collection(db, "cake_ladoo");
        const ladooSnapshot = await getDocs(ladooCollection);
        const ladooList = await Promise.all(
          ladooSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const storageRef = ref(storage, data.image);

            const imageURL = await getDownloadURL(storageRef);
            return {
              id: doc.id,
              name: data.name,
              image: imageURL,
              order: data.order !== undefined ? data.order : Infinity,
            };
          })
        );
        ladooList.sort((a, b) => a.order - b.order);

        setLadoos(ladooList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCakes();
  }, []);
  const handleClick = (link) => {
    navigate(`/${link}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Cake Ladoo Portfolio | Cake Layers</title>
        <meta
          name="description"
          content="Explore our Cake Ladoo portfolio at Cake Layers. Discover unique and customizable ladoos that blend modern indulgence with tradition."
        />
        <meta
          name="keywords"
          content="cake ladoos, ladoo portfolio, Cake Layers, customizable ladoos, traditional sweets, modern indulgence, eggless, vegan"
        />
        <meta
          property="og:title"
          content="Cake Ladoo Portfolio | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore our Cake Ladoo portfolio at Cake Layers. Discover unique and customizable ladoos that blend modern indulgence with tradition."
        />
        <meta
          property="og:url"
          content="https://cakelayers.web.app/cake-ladoo"
        />{" "}
        <meta property="og:type" content="website" />
      </Helmet>

      <section>
        <div className="product_hero_container">
          <h1>Cake Ladoo Portfolio</h1>
          <p id="hero_subtitle">Reimagine Traditional South Asian Sweets</p>
          <p id="hero_paragraph">
            <span>
              Imagine opening a beautifully arranged box of Cake Ladoo with your
              family. A card inside sparks excitement and fuels the conversation
              of which flavour to pick first! But what are Cake Ladoo?
              Delicately handcrafted sweets, combined with the richness of
              eastern and western flavours and the elegance of modern dessert
              artistry. Cake Ladoo are perfect for any occasion - from weddings
              and birthdays to festive gatherings and special events. Made from
              my signature egg-free cakes and flavoursome buttercreams, each
              Cake Ladoo is finished with an artisanal flair.
            </span>
          </p>
          <button className="brownBtn" onClick={() => handleClick("contact")}>
            Enquire
          </button>
        </div>
      </section>

      <section id="product_gallery_cont">
        <div className="product_gallery">
          {ladoos.map((product) => (
            <Cake key={product.id} product={product} />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default CakeLadoo;
