import LadooPreview from "../../components/ladooPreviewComponent/LadooPreview";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./CakeLadooStyle.css";

const CakeLadoo = () => {
  return (
    <HelmetProvider>
      {/* <section>
        <div className="product_hero_container">
          <h1>Cake Ladoo Ordering</h1>
          <p id="hero_paragraph">
            <span>
              I have created the perfect Cake Ladoo box ordering experience for
              you. Cake Ladoo are decorated shown. Optional extra toppings can
              be added and priced accordingly. Follow the steps to see your
              filled Cake Ladoo box
            </span>
          </p>
        </div>
      </section> */}
      <section>
        <div className="product-page">
          <div className="product_hero_container">
            <h1>Cake Ladoo Ordering</h1>
            <p>
              <span>
                Each Cake Ladoo is finished with artistic flair, adorned with a
                delicate topping, and crafted to captivate the eye while
                tantalising the taste buds. To create your perfect Cake Ladoo
                gifting box, follow the steps below.
              </span>
            </p>
          </div>
        </div>
      </section>
      <section>{<LadooPreview />}</section>
    </HelmetProvider>
  );
};

export default CakeLadoo;
