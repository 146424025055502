import "./WeddingCakesStyle.css";
import "./SharedProductsStyles.css";
import { useNavigate } from "react-router-dom";

import { useCakeContext } from "../../context/CakeContext";
import Cake from "../../components/productsComponents/ProductCard";
import { Helmet, HelmetProvider } from "react-helmet-async";

const WeddingCakes = () => {
  const navigate = useNavigate();
  const { cakes, loading, error } = useCakeContext();
  const weddingCakes = cakes.filter((cake) => cake.category === "wedding");
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  const handleClick = (link) => {
    navigate(`/${link}`);
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Elegant Wedding Cakes | Cake Layers</title>
        <meta
          name="description"
          content="Explore our collection of elegant wedding cakes at Cake Layers. Discover exquisite designs and unmatched flavours for your special day."
        />
        <meta
          name="keywords"
          content="wedding cakes, elegant cakes, custom wedding cakes, Cake Layers, wedding cake designs"
        />
        <meta
          property="og:title"
          content="Elegant Wedding Cakes | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore our collection of elegant wedding cakes at Cake Layers. Discover exquisite designs and unmatched flavours for your special day."
        />
        {/* Replace with an appropriate image URL */}
        <meta
          property="og:url"
          content="https://cakelayers.web.app/wedding-cakes"
        />{" "}
        {/* Update with your actual URL */}
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="product_hero_container">
          <div className="product_hero_img_container text_container"></div>
          <h1>Elegant Wedding Cakes</h1>
          <p id="hero_subtitle">
            Exquisite Designs and Unmatched Flavours for Your Special Day
          </p>
          <p id="hero_paragraph" className="wedding_hero">
            <span>
              As a cake artist, I understand that your wedding day is one of the
              most important and memorable days of your life. It’s an honour to
              create a wedding cake that beautifully symbolises love and unity
              while radiating both elegance and exceptional taste.
            </span>
            <span>
              From delicate buttercream finishes to intricate fondant details,
              Cake Layers wedding cakes offer a perfect blend of artistic beauty
              and taste.
            </span>
            <span>
              Drawing on the latest trends in textures, colours and shapes, you
              can choose to add elements that mirror you as a couple, reflect
              your attire, or echo the venue décor and architecture. Your cake
              will be uniquely tailored to bring your vision to life.
            </span>
            <span>
              Based in the West Midlands, I can travel anywhere between
              Liverpool and London to deliver and set up your wedding cake.
            </span>
          </p>
          {/* <a className="hero_btn_link" onClick={() => handleClick("contact")}>
            Enquire
          </a> */}
          <button className="brownBtn" onClick={() => handleClick("contact")}>
            Enquire
          </button>
        </div>
      </section>
      <section id="product_gallery_cont">
        <div className="product_gallery">
          {weddingCakes.map((product) => (
            <Cake key={product.id} product={product} />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default WeddingCakes;
