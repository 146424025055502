import "./SharedProductsStyles.css";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Cake from "../../components/productsComponents/ProductCard";
import { useNavigate } from "react-router-dom";

import {
  db,
  storage,
  collection,
  getDocs,
  ref,
  getDownloadURL,
} from "../../firebase";
const Cupcakes = () => {
  const [cupcakes, setCupcakes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCupcakes = async () => {
      try {
        const cupcakesCollection = collection(db, "cupcakes");
        const cupcakesSnapshot = await getDocs(cupcakesCollection);
        const cupcakeList = await Promise.all(
          cupcakesSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const storageRef = ref(storage, data.main_image);

            const imageURL = await getDownloadURL(storageRef);
            return {
              id: doc.id,
              name: data.name,
              image: imageURL,
              order: data.order !== undefined ? data.order : Infinity,
            };
          })
        );
        cupcakeList.sort((a, b) => a.order - b.order);

        setCupcakes(cupcakeList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCupcakes();
  }, []);
  const handleClick = (link) => {
    navigate(`/${link}`);
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Cupcakes Portfolio | Cake Layers</title>
        <meta
          name="description"
          content="Explore our Cupcakes Portfolio at Cake Layers. Our cupcakes are a celebration of taste and craftsmanship, perfect for any occasion, with each one beautifully decorated to elevate your event."
        />
        <meta
          name="keywords"
          content="cupcakes, cupcake portfolio, Cake Layers, sweet treats, decorative cupcakes, event cupcakes"
        />
        <meta property="og:title" content="Cupcakes Portfolio | Cake Layers" />
        <meta
          property="og:description"
          content="Explore our Cupcakes Portfolio at Cake Layers. Our cupcakes are a celebration of taste and craftsmanship, perfect for any occasion, with each one beautifully decorated to elevate your event."
        />
        <meta property="og:url" content="https://cakelayers.web.app/cupcakes" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="product_hero_container">
          <h1>Cupcakes Portfolio</h1>
          <p id="hero_subtitle">Small Cakes, Big Impact</p>
          <p id="hero_paragraph">
            Perfect for celebrations, corporate events, or simply enjoying a
            sweet moment, cupcakes combine aesthetic appeal with just the right
            amount indulgence. Each cupcake is beautifully decorated to add a
            touch of charm and sophistication to your event.
          </p>
          <button className="brownBtn" onClick={() => handleClick("contact")}>
            Enquire
          </button>
        </div>
      </section>
      <section id="product_gallery_cont">
        <div className="product_gallery">
          {cupcakes.map((product) => (
            <Cake key={product.id} product={product} />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Cupcakes;
