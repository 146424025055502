import React, { useState, useEffect } from "react";
import { useCart } from "../../context/CartContext";
import { useLadoo } from "../../context/LadooContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSyncAlt } from "react-icons/fa"; // Import the refresh icon

const LadooPreview = () => {
  const { addToCart } = useCart();
  const { flavours, toppings } = useLadoo(); // Access flavours, toppings from context
  const [selectedFlavour, setSelectedFlavour] = useState("");
  const [selectedTopping, setSelectedTopping] = useState(null); // Allow null for optional topping
  const [previewItems, setPreviewItems] = useState(Array(9).fill(null)); // Initialize with 9 empty slots
  const [ladoosCount, setLadoosCount] = useState(9); // Default to 9 ladoos
  const [flavourOptions, setFlavourOptions] = useState([]); // Flavour options based on ladoo count
  const [selectedFlavoursCount, setSelectedFlavoursCount] = useState(null); // Number of flavours to select

  useEffect(() => {
    // Update flavour options based on ladoo count
    switch (ladoosCount) {
      case 4:
        setFlavourOptions([4, 2, 1]);
        break;
      case 6:
        setFlavourOptions([6, 2, 1]);
        break;
      case 9:
        setFlavourOptions([9, 3, 1]);
        break;
      case 16:
        setFlavourOptions([4, 8, 1]);
        break;
      default:
        setFlavourOptions([]);
    }
  }, [ladoosCount]);

  const handleLadooCountChange = (count) => {
    setLadoosCount(count);
    setPreviewItems(Array(count).fill(null)); // Reset preview items based on new ladoos count
    setSelectedFlavoursCount(null); // Reset flavour selection when changing ladoos count
  };

  const handleFlavourCountChange = (option) => {
    setPreviewItems(Array(ladoosCount).fill(null));
    setSelectedFlavoursCount(option);
  };

  const calculateLadoosToAdd = () => {
    if (selectedFlavoursCount) {
      return ladoosCount / selectedFlavoursCount;
    }
    return 0;
  };

  const addToBox = () => {
    if (!selectedFlavoursCount) {
      toast.info("Please select the number of flavours!");
      return;
    }

    if (selectedFlavour) {
      const flavour = flavours.find(
        (flavour) => flavour.id === selectedFlavour
      );
      const topping = selectedTopping
        ? toppings.find((topping) => topping.id === selectedTopping)
        : null;

      const ladoosToAdd = calculateLadoosToAdd();
      const newPreviewItems = [...previewItems];
      let addedCount = 0;

      // Add ladoos to the box based on ladoosToAdd
      for (let i = 0; i < newPreviewItems.length; i++) {
        if (newPreviewItems[i] === null && addedCount < ladoosToAdd) {
          newPreviewItems[i] = { flavour, topping };
          addedCount++;
        }
        if (addedCount === ladoosToAdd) break;
      }

      if (addedCount === 0) {
        toast.warn(
          "Ladoo Box is full or no slots available for the selected number of ladoos"
        );
      } else if (addedCount < ladoosToAdd) {
        toast.warn(
          "Not enough empty slots available for the selected number of ladoos"
        );
      }

      setPreviewItems(newPreviewItems);
    } else {
      toast.info("Please select a flavour!");
    }
  };

  const handleAddToCart = () => {
    if (previewItems.every((item) => item !== null)) {
      addToCart({ items: previewItems, type: "ladooBox" });
      toast.success("Items added to cart!");
      setPreviewItems(Array(ladoosCount).fill(null));
    } else {
      toast.warn("Please complete all slots before adding to the cart.");
    }
  };

  const handleRefreshBox = () => {
    setPreviewItems(Array(ladoosCount).fill(null)); // Clear the box
    toast.info("Box has been refreshed!");
  };

  return (
    <>
      <div className="order_instructions text_container">
        <h3>How to Order</h3>
        <ul>
          <li>
            <p>1. Choose your box size</p>
          </li>
          <li>
            <p>2. Choose the number of different flavours you would like.</p>
          </li>
          <li>
            <p>3. Choose your first flavour</p>
          </li>
          <li>
            <p>4. Choose any extra toppings (Optional)</p>
          </li>
          <li>
            <p>5. Add your flavour to the box</p>
          </li>
          <li>
            <p>6. Repeat for all other flavours</p>
          </li>
        </ul>
      </div>

      <div className="product-container">
        {/* Ladoo Count Selection */}

        <div className="ladoo_choice_container">
          <div className="ladoos-count-selection ladoo_selection_txt_container text_container">
            {/* <h3>Cake Ladoo box size</h3>
          {[4, 6, 9, 12].map((count) => (
            <button
              key={count}
              className={`ladoos-button ${
                ladoosCount === count ? "selected" : ""
              }`}
              onClick={() => handleLadooCountChange(count)}
            >
              {count}
            </button>
          ))} */}
            <h3>Cake Ladoo box size</h3>
            {[4, 6, 9, 16].map((count) => (
              <button
                key={count}
                className={`ladoos-button ${
                  ladoosCount === count ? "selected" : ""
                }`}
                onClick={() => handleLadooCountChange(count)}
                disabled={count !== 9} // Disable all sizes except 9
              >
                {count}
              </button>
            ))}
            <p className="availability-note">
              Currently, only the 9-ladoo box size is available. Other sizes
              will be added soon.
            </p>
          </div>
          {/* Flavour Options Selection */}
          <div className="ladoos-count-selection ladoo_selection_txt_container text_container">
            <h3>Choose Number of Flavours</h3>

            {flavourOptions.map((option) => (
              <button
                key={option}
                className={`ladoos-button ${
                  selectedFlavoursCount === option ? "selected" : ""
                }`}
                onClick={() => handleFlavourCountChange(option)}
              >
                {option}
              </button>
            ))}
          </div>
          {/* Flavour Selection */}
          <div className="selection">
            <div className="ladoo_selection_txt_container text_container">
              <h3>Choose Flavour</h3>
            </div>
            <div className="flavour-options">
              {flavours.map((flavour) => (
                <div key={flavour.id} className="flavour-item">
                  <img
                    src={flavour.image}
                    alt={flavour.name}
                    data-flavour={flavour.id}
                    className={`flavour-image ${
                      selectedFlavour === flavour.id ? "selected" : ""
                    }`}
                    onClick={() => setSelectedFlavour(flavour.id)}
                  />
                  <p className="flavour-name">{flavour.name}</p>
                </div>
              ))}
            </div>
          </div>
          {/* Topping Selection (Optional) */}
          <div className="selection">
            <div className="ladoo_selection_txt_container text_container">
              <h3>
                Choose Extra Topping (Optional and with an additional cost)
              </h3>
            </div>
            <div className="topping-options">
              <button
                className={`topping-button ${
                  selectedTopping === null ? "selected" : ""
                }`}
                onClick={() => setSelectedTopping(null)}
              >
                No Topping
              </button>
              {toppings.map((topping) => (
                <button
                  key={topping.id}
                  className={`topping-button ${
                    selectedTopping === topping.id ? "selected" : ""
                  }`}
                  onClick={() => setSelectedTopping(topping.id)}
                >
                  {topping.name}
                </button>
              ))}
            </div>
          </div>
          {/* Add to Box Button */}
          <div className="add_btn_container">
            <div className="add_btn_sub_cont">
              <button className="pinkBtn" onClick={addToBox}>
                Add to Box
              </button>
            </div>
          </div>
        </div>

        {/* Ladoo Grid inside a Cardboard Box Image */}
        <div className="preview_container">
          <div className="ladoo_box_sticky">
            <div className="sub_preview_container">
              <div className="cardboard-container">
                <div className={`ladoo-grid ladoos-${ladoosCount}`}>
                  {previewItems.map((item, index) => (
                    <div
                      key={index}
                      className={`ladoo-box`}
                      data-index={index + 1}
                    >
                      {item ? (
                        <>
                          <img
                            src={item.flavour.image}
                            alt={item.flavour.name}
                          />
                          <p>{item.flavour.name}</p>
                          {item.topping && <p>{item.topping.name}</p>}
                        </>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <button
              className="refresh-button brownBtn"
              onClick={handleRefreshBox}
            >
              {/* <FaSyncAlt /> */} Empty Box
            </button>

            <div className="sub_preview_container preview_text text_container">
              <p>
                Click <span>Add to Cart</span> when you are happy with your Cake
                Ladoo box. I will review your order with a follow up email to
                ensure everything is tailored to your needs.
              </p>
              <p>
                Note: For personalised messages, or sugan trays/table
                favours/dessert displays, please email via contact page.
              </p>
              <div className="add_btn_sub_cont">
                <button className="pinkBtn" onClick={handleAddToCart}>
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LadooPreview;
