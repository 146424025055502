import "./HomeStyle.css";
import cake_banner_img1 from "../../assets/cake_banner_img1.jpg"; // with import
import ladoo_img from "../../assets/ladooHeroImg.jpg";
import commitment_img from "../../assets/commitment_img.jpg";
import hero_img from "../../assets/hero_img.jpg";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import cakeLadooLogo from "../..//assets/cakeLadooLogo.png";

export default function Home() {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(`/${link}`);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Artisanal Cakes & Desserts | Cake Layers</title>
        <meta
          name="description"
          content="Explore luxury custom cakes for weddings and special occasions. Cake Layers offers exquisite designs and flavours."
        />
        <meta
          name="keywords"
          content="cakes, wedding cakes, custom cakes, luxury cakes, special occasion cakes, cake ladoo, ladoo"
        />
        <meta
          property="og:title"
          content="Artisanal Cakes & Desserts | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore luxury custom cakes for weddings and special occasions. Cake Layers offers exquisite designs and flavours."
        />
        <meta property="og:image" content={cake_banner_img1} />
        <meta property="og:url" content="https://cakelayers.web.app/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        <div className="hero_outer_container">
          <div className="parallax">
            <img src={hero_img} alt="5 tier wedding cake" />
          </div>
          <div className="title_container">
            <div className="hero_title text_container">
              <h1>LUXURY CAKES</h1>
            </div>
            {/* ############ */}
            <div className="hero_subtitle hero_paragraph text_container">
              <svg
                className="line-top"
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="2"
                viewBox="0 0 100 2"
              >
                <line
                  x1="0"
                  y1="1"
                  x2="65"
                  y2="1"
                  stroke="#6a363b"
                  strokeWidth="2"
                />
              </svg>
              <p>Elegant, opulent, and always indulgent</p>
              <div className="second_line">
                <svg
                  className="line-bottom"
                  xmlns="http://www.w3.org/2000/svg"
                  width="160"
                  height="2"
                  viewBox="0 0 200 2"
                >
                  <line
                    x1="0"
                    y1="1"
                    x2="200"
                    y2="1"
                    stroke="#6a363b"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            {/* ############## */}
            <div className="hero_paragraph text_container">
              <p>
                An exquisite cake experience using quality ingredients to
                celebrate life's sweetest moments.
              </p>
              <p>
                <span>
                  Discover Cake Ladoo: A contemporary twist on traditional South
                  Asian sweets
                </span>
              </p>
            </div>

            <button
              className="brownBtn home_hero_btn"
              onClick={() => handleClick("contact")}
            >
              CONTACT ME
            </button>
          </div>
        </div>

        {/* ################################################################################ */}

        <section className="cake_feature_banner">
          <div className="otr_cont_cake">
            <div className="cake_txt_cont text_container">
              <h2>Crafting Excellence</h2>
              <p>
                With over 15 years of baking experience, I’m dedicated to
                ensuring you get a professional yet friendly service from the
                moment of contact. Whether you’re looking for a simple elegant
                cake or a tall grand design, you will feel at ease knowing I
                will understand your style to create the stunning masterpiece
                you desire.
                <br />
                <br />
                Based in the West Midlands, I cover all surrounding areas,
                London and Manchester.
              </p>
              <button
                className="pinkBtn"
                onClick={() => handleClick("wedding-cakes")}
              >
                PORTFOLIO
              </button>
            </div>

            <div className="cake_feature_image_container">
              <img
                src={cake_banner_img1}
                alt="Elegant 5-tier square cake with 2nd and 4th tiers made of transparent glass, showcasing flowers inside."
                className="image"
              />
            </div>
          </div>
        </section>

        {/* <div id="test">
          <img src={cakeLadooLogo} />
        </div> */}

        <section className="ladoo_section">
          <div className="main_ladoo_container">
            <div className="ladoo_content outer_ladoo_img_cont">
              <img src={ladoo_img} alt="a bowl of cake ladoos" />
            </div>
            <div className="ladoo_content outer_ladoo_text_container">
              <div className="inner_ladoo_text_container text_container">
                <h2>Discover The Brand - Cake Ladoo</h2>
                <p id="subtitle_ladoo">
                  The Perfect Fusion of Tradition and Sophistication
                </p>
                <p>
                  Embark on a new journey of the popular South Asian ladoo. An
                  innovative blend, crafted to add a luxurious touch to your
                  celebrations.
                </p>
                <button
                  className="pinkBtn"
                  onClick={() => handleClick("cake-ladoo")}
                >
                  PORTFOLIO
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="cards_container">
            <div className="cards_text_container">
              <div className="text_container">
                <h2>More to Explore</h2>
              </div>
            </div>
            <div className="cards_link_container">
              <div onClick={() => handleClick("flavours")} className="crd ">
                <div className="crd_content crd_flavours"></div>
                <div className="crd_label">
                  <p>FLAVOURS</p>
                </div>
              </div>
              <div onClick={() => handleClick("testimonials")} className="crd">
                <div className="crd_content card_testimonials">
                  {/* <p>Hear from Our Delighted Customers!</p> */}
                </div>
                <div className="crd_label">
                  <p>TESTIMONIALS</p>
                </div>
              </div>
              <div onClick={() => handleClick("cupcakes")} className="crd">
                <div className="crd_content crd_cupcakes"></div>
                <div className="crd_label">
                  <p>CUPCAKES</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="mission_container">
            <div className="mission_content mission_image">
              <img src={commitment_img} alt="Owner" />
            </div>
            <div className="mission_content">
              <div className="mission_text text_container">
                <h2>MY COMMITMENT</h2>

                <p>
                  It is my mission to offer all clients a diverse selection of
                  sponge flavours and fillings, whether you’re looking for a
                  traditional sponge, egg-free, or vegan cake. By adapting
                  recipes and getting creative with ingredients to accommodate
                  various dietary needs, I design bespoke cakes that are not
                  only visually stunning but also delicious and guaranteed to
                  exceed your expectations.
                </p>

                <button
                  className="brownBtn"
                  onClick={() => handleClick("about")}
                >
                  About
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
}
