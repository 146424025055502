import "./PricingInformationStyle.css";
import pricingWeddingImg from "../../assets/pricingWeddingImg.jpg";
import pricingCelebrationImg from "../../assets/pricingCelebrationImg.jpg";
import pricingLadooImg from "../../assets/pricingLadooImg.jpg";
import pricingCupcakesImg from "../../assets/pricingCupcakesImg.jpg";
import pricingDessertImg from "../../assets/pricingDessertImg.jpg";
import { handlePortionGuideClick } from "../../utils/portionGuide";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function FlavourInformation() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Elegant Cake Designs & Pricing | Cake Layers</title>
        <meta
          name="description"
          content="Explore our custom cake pricing for weddings, celebrations, and desserts. Cake Layers offers exquisite designs with personalized touches."
        />
        <meta
          name="keywords"
          content="cake pricing, wedding cakes, celebration cakes, custom cakes, cake ladoo, cupcakes, dessert pricing"
        />
        <meta
          property="og:title"
          content="Elegant Cake Designs & Pricing | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore our custom cake pricing for weddings, celebrations, and desserts. Cake Layers offers exquisite designs with personalized touches."
        />
        <meta property="og:url" content="https://cakelayers.web.app/pricing" />{" "}
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="product_hero_container text_container price_hero">
          <h1>Price Guide</h1>
          <p id="hero_paragraph">
            <br />
            Pricing varies based on the chosen flavour, finish, and level of
            detail.
          </p>

          <button className="brownBtn" onClick={handlePortionGuideClick}>
            View Portion Guide
          </button>
        </div>
      </section>

      <section>
        <div className="pricing_outr_container">
          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingWeddingImg}
                alt="An elegant two-tier cake adorned with delicate white flower decorations."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Wedding Cakes</h2>
              <p>
                The price of wedding cakes will take into consideration the
                flavour, number of actual cake tiers, number of decorated dummy
                tiers, design detail and intricate embellishments such as
                monograms and gold leaf.
              </p>
              <br />
              <p>
                The following prices are a guide for decorated wedding cakes
                made of all actual cake tiers. Please refer to the portion guide
                at the top of this page for the number of servings per tier.
              </p>
              <ul>
                <li>
                  <p>2-Tier (8/6”)</p>
                  <p>£450</p>
                </li>
                <li>
                  <p>3-Tier (10/8/6”)</p>
                  <p>£650</p>
                </li>
                <li>
                  <p>4-tier (10/8/6/4”)</p>
                  <p>£800</p>
                </li>
                <li>
                  <p>5-tier (12/10/8/6/4”)</p>
                  <p>£1250</p>
                </li>
              </ul>
              <p>
                <br />
                <span>Tiered cakes cannot be collected.</span>
              </p>
              <p>
                Standard local delivery is £35 for travel within 5 miles to your
                venue. There is an additional 50p charge per mile for a return
                journey over 5 miles and an hourly rate charge.
                <br />
                Cake stands, cake knife and display props are available with a
                hire charge. Please let me know if you require this service with
                your wedding cake enquiry.
              </p>
            </div>
          </div>

          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingLadooImg}
                alt="A beautiful box containing nine ladoos, each crafted with care and vibrant colors."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Cake Ladoo</h2>

              <span>
                <p>Try Cake Ladoo flavours before you place your order.</p>
              </span>
              <p>
                9 Flavour Taster Box: £25 (no nut flavours), £30 (with 3 nut
                flavours)
              </p>
              <div className="pricing_grid">
                <div>
                  <span>
                    <p>4 Cake Ladoo Box (Coming soon)</p>
                  </span>
                  <span>
                    <p>Available in 1,2 and 4 flavours per box</p>
                  </span>
                  <p>From - £5.50</p>
                  <p>Minimum order 40 boxes</p>
                </div>
                <div>
                  <span>
                    <p>6 Cake Ladoo Box (Coming soon)</p>
                  </span>
                  <span>
                    <p>Available in 1,2 and 6 flavours per box</p>
                  </span>
                  <p>From £7.00</p>
                  <p>Minimum order 30 boxes</p>
                </div>
                <div>
                  <span>
                    <p>9 Cake Ladoo Box</p>
                  </span>
                  <span>
                    <p>Available in 1,3 and 9 flavours per box</p>
                  </span>
                  <p>1 flavour - £8.50</p>
                  <p>Minimum order 20 boxes</p>
                </div>
                <div>
                  <span>
                    <p>16 Cake Ladoo Box (Coming soon)</p>
                  </span>
                  <span>
                    <p>Available in 4 and 8 flavours per box</p>
                  </span>
                  <p>From £25.00</p>
                  <p>Minimum order 5 boxes</p>
                </div>
              </div>

              <span>
                <br />
                <p>Single Cake Ladoo start from £1.75 each </p>
                <p>Extra toppings start from 25p per Cake Ladoo.</p>
                <p>Extra nut toppings 50p per Cake Ladoo </p>
                <p>Messages £1 per box</p>
                <p>
                  (Check out the additional toppings on the Order Cake Ladoo
                  page.)
                </p>
                <br />
              </span>
            </div>
          </div>

          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingCelebrationImg}
                alt="A stunning pink round birthday cake adorned with various makeup items on top, creating a glamorous celebration theme."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Celebration Cakes</h2>

              <p>Sizes start from 5”</p>
              <br />
              <ul>
                <li>
                  <p>Buttercream finish</p>
                  <p>£90</p>
                </li>
                <li>
                  <p>Sugar paste icing</p>
                  <p>£110</p>
                </li>
              </ul>
              <p>
                <br />
                Delivery available – charges apply (tiered cakes are delivery
                only).
              </p>
            </div>
          </div>

          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingCupcakesImg}
                alt="Stunning cupcakes topped with smooth white buttercream and vibrant, colorful sprinkles, perfect for any festive occasion."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Cupcakes</h2>
              <p>
                There is a minimum order quantity of 12 cupcakes. Prices vary
                depending on the flavour, topping and design detail.
              </p>
              <br />
              <p>Cupcakes finished with:</p>
              <ul>
                <li>
                  <p>Buttercream swirls</p>
                  <p>£48</p>
                </li>
                <li>
                  <p>Edible toppers</p>
                  <p>£54</p>
                </li>
                <li>
                  <p>Decorated fondant domes</p>
                  <p>£72</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingDessertImg}
                alt="An elegant tower showcasing two flavours of macarons, beautifully arranged for a sophisticated touch at any event."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Desserts</h2>
              <p>
                The prices below are a start price for each dessert option. The
                cost of each dessert will depend on the flavour, filling and
                design detail. Prices do not include packaging.
              </p>
              <ul>
                <li>
                  <p>Cake Pops</p>
                  <p>£4.50 each (minimum order 30)</p>
                </li>
                <li>
                  <p>Cakesicles</p>
                  <p>£5.50 each (minimum order 30)</p>
                </li>
                <li>
                  <p>Macarons</p>
                  <p>£2.00 each (minimum order 50)</p>
                </li>
                <li>
                  <p>Cake Barfi</p>
                  <p>£1.75 each (minimum order 30)</p>
                </li>
                <li>
                  <p>Cake Ladoo Table Favours</p>
                  <p>£2.50 each (minimum order 50)</p>
                </li>
                <li>
                  <p>Mini Chocolate Christmas puddings</p>
                  <p>£2.00 each (minimum order 30)</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
}
