import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import "./ScrollToTopButtonStyle.css"; // Import your CSS file

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Function to check scroll position and toggle visibility
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const screenHeight = window.innerHeight;
    const scrollPercentage = (scrollY / screenHeight) * 100;

    if (scrollPercentage > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Function to scroll back to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section id="toTopBtn">
        {isVisible && (
          <div className="scroll-to-top-button" onClick={scrollToTop}>
            <FontAwesomeIcon icon={faArrowUp} />
          </div>
        )}
      </section>
    </>
  );
}

export default ScrollToTopButton;
