// src/pages/Products.js
import "./CorporateProductsStyle.css";
import "./SharedProductsStyles.css";
import React, { useEffect, useState } from "react";
import Cake from "../../components/productsComponents/ProductCard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import {
  db,
  storage,
  collection,
  getDocs,
  ref,
  getDownloadURL,
} from "../../firebase";

const CorporateProducts = () => {
  const [corporate, setCorporate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCorporate = async () => {
      try {
        const corporateCollection = collection(db, "corporate");
        const corporateSnapshot = await getDocs(corporateCollection);
        const corporateList = await Promise.all(
          corporateSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const storageRef = ref(storage, data.image);

            const imageURL = await getDownloadURL(storageRef);
            return {
              id: doc.id,
              name: data.name,
              image: imageURL,
              order: data.order !== undefined ? data.order : Infinity,
            };
          })
        );
        corporateList.sort((a, b) => a.order - b.order);
        setCorporate(corporateList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCorporate();
  }, []);

  const handleClick = (link) => {
    navigate(`/${link}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Corporate Cakes Portfolio | Cake Layers</title>
        <meta
          name="description"
          content="Discover our collection of corporate cakes at Cake Layers. Perfect for company milestones, product launches, and corporate events. Explore designs that add elegance and professionalism to your occasion."
        />
        <meta
          name="keywords"
          content="corporate cakes, company cakes, milestone cakes, product launch cakes, Cake Layers, custom corporate cakes"
        />
        <meta
          property="og:title"
          content="Corporate Cakes Portfolio | Cake Layers"
        />
        <meta
          property="og:description"
          content="Discover our collection of corporate cakes at Cake Layers. Perfect for company milestones, product launches, and corporate events. Explore designs that add elegance and professionalism to your occasion."
        />
        <meta
          property="og:url"
          content="https://cakelayers.web.app/corporate-cakes"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="product_hero_container">
          <h1>Corporate Cakes Portfolio</h1>
          <p id="hero_subtitle">Make a Lasting Impression</p>
          <p id="hero_paragraph">
            Elevate your corporate events with custom cakes that showcase your
            brand's colours and logo. Whether you're celebrating a milestone,
            launching a new product, thanking your suppliers, or hosting a
            company event, Cake Layers will bring sophistication and
            professionalism to every occasion. Get in touch to let me help you
            make a memorable impression.
          </p>
          <button className="brownBtn" onClick={() => handleClick("contact")}>
            Enquire
          </button>
        </div>
      </section>

      <section id="product_gallery_cont">
        <div className="product_gallery">
          {corporate.map((product) => (
            <Cake key={product.id} product={product} />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default CorporateProducts;
