import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./HeaderStyle.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal"; // Import Modal
import Button from "react-bootstrap/Button"; // Import Button
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import logo from "../..//assets/LogoSingleColour.png";
import cakeLadooLogo from "../..//assets/cakeLadooLogo.png";

import { useCart } from "../../context/CartContext";
import { useNavigate } from "react-router-dom";

function CustomNavbar() {
  const navigate = useNavigate();
  const [showCartModal, setShowCartModal] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const { getCartItemCount, getCartData, removeItemFromCart } = useCart();

  const handleClose = () => setShowCartModal(false);
  const handleShow = () => setShowCartModal(true);

  const toggleExpandItem = (key) => {
    setExpandedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleDeleteItem = (index) => {
    removeItemFromCart(index);
  };

  const renderCartItems = () => {
    const cartItems = getCartData();

    if (!cartItems || cartItems.length === 0) {
      return <p>Your cart is empty.</p>;
    }

    return cartItems.map((cartItem, index) => {
      const firstItem = cartItem.items;

      if (!firstItem) {
        return <p key={index}>Error loading cart item.</p>;
      }

      // Determine the type of item and render accordingly
      if (cartItem.type === "ladooBox") {
        return renderLadooItem(firstItem, index);
      } else if (cartItem.type === "galleryImage") {
        return renderGalleryItem(firstItem, index);
      } else {
        return <p key={index}>Unknown item type.</p>;
      }
    });
  };

  const renderLadooItem = (ladooBox, key) => {
    const isExpanded = expandedItems[key];

    return (
      <div key={key} className="cart-item">
        <div className="top_info">
          <div className="item_info">
            <p>Ladoo Box Size: {ladooBox.length}</p>
          </div>
          <div>
            <button onClick={() => handleDeleteItem(key)}>Delete</button>
          </div>
        </div>
        <button onClick={() => toggleExpandItem(key)}>
          {isExpanded ? "View Less" : "View More"}
        </button>
        {isExpanded && (
          <ul className="ladoo_list">
            {ladooBox.map((ladoo, index) => (
              <li key={index} className="ladoo-detail">
                <p>Flavour: {ladoo.flavour?.name || "Unknown"}</p>
                <p>Topping: {ladoo.topping?.name || "None"}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const renderGalleryItem = (item, key) => (
    <div key={key} className="cart-item">
      <div key={key} className="gallery-detail">
        <div className="top_info">
          <div className="item_info">
            <img
              src={item.image}
              alt={item.description || "Gallery Image"}
              style={{ width: "100px", height: "100px" }}
            />
            <p>Name: {item.name || ""}</p>
          </div>
          <div>
            <button onClick={() => handleDeleteItem(key)}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );

  const handleEnquire = () => {
    navigate("/contact");
    handleClose();
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="navbar-custom">
        <Container fluid>
          <Navbar.Brand href="/home" className="d-flex align-items-center">
            <img src={logo} alt="Company Logo" style={{ width: "150px" }} />
            {/* <img
              src={cakeLadooLogo}
              alt="Company Logo"
              style={{ width: "120px", margin: "0 2rem " }}
            /> */}
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{
              boxShadow: "none",
              outline: "none",
              border: "none",
            }}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="align-items-center">
              <NavDropdown title="Portfolio" id="collapsible-nav-dropdown">
                <NavDropdown.Item href="/wedding-cakes">
                  Wedding Cakes
                </NavDropdown.Item>
                <NavDropdown.Item href="/cake-ladoo">
                  Cake Ladoo
                </NavDropdown.Item>
                <NavDropdown.Item href="/celebration-cakes">
                  Celebration Cakes
                </NavDropdown.Item>
                <NavDropdown.Item href="/corporate">Corporate</NavDropdown.Item>
                <NavDropdown.Item href="/cupcakes">Cupcakes</NavDropdown.Item>
                <NavDropdown.Item href="/desserts">Desserts</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Information" id="collapsible-info-dropdown">
                <NavDropdown.Item href="/flavours">Flavours</NavDropdown.Item>
                <NavDropdown.Item href="/pricing">Pricing</NavDropdown.Item>
                <NavDropdown.Item href="/testimonials">
                  Testimonials
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/cake-Ladoo-Order">Order Cake Ladoo</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>

              <Nav.Link
                href="https://www.facebook.com/cakelayersofficial/"
                target="_blank"
                className="icn"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </Nav.Link>
              <Nav.Link
                href="https://www.instagram.com/cakelayersofficial/?hl=en"
                target="_blank"
                className="icn"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </Nav.Link>
              <Nav.Link
                href="https://www.linkedin.com/in/purmilla-bhatti-92b5a87b/?originalSubdomain=uk"
                target="_blank"
                className="icn"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </Nav.Link>

              {/* Shopping Cart Icon */}
              <Nav.Link className="icn-cart" onClick={handleShow}>
                <FontAwesomeIcon icon={faShoppingBasket} />
                <span className="cart-item-count">{getCartItemCount()}</span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Cart Modal */}
      <Modal show={showCartModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Items of Interest</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">{renderCartItems()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEnquire}>
            Enquire
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default CustomNavbar;
