import React, { useState, useEffect } from "react";
import { db, collection, getDocs } from "../../firebase";
import { useLadoo } from "../../context/LadooContext";
import Flavour from "../../components/productsComponents/Flavour";
import flavourWeddingCakeImg from "../../assets/weddingCake_img.jpg";
import flavourLadooImg from "../../assets/flavour_ladoo_img.jpg";
import flavourToppingImg from "../../assets/flavour_toping_img.jpeg";
import flavourDessertImg from "../../assets/flavourDessertImg.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./FlavourInformationStyle.css";

export default function FlavourInformation() {
  const [weddingFlavours, setWeddingCakeFlavours] = useState([]);
  const [fillingFlavours, setFillingFlavours] = useState([]);
  const [buttercreamFlavour, setButtercreamFlavour] = useState([]);
  const [dessertFlavours, setDessertFlavours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Getting ladoo flavours and toppings from LadooContext
  const { flavours, toppings } = useLadoo();

  useEffect(() => {
    const fetchCakes = async () => {
      try {
        const flavoursCollection = collection(db, "other_flavours");
        const flavourSnapshot = await getDocs(flavoursCollection);

        const flavourList = flavourSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          type: doc.data().type,
        }));

        // Filter the flavours based on their type
        const wCakeFlavours = flavourList
          .filter((item) => item.type === "weddingCakes")
          .sort((a, b) => a.name.localeCompare(b.name));
        const dessertFlavours = flavourList
          .filter((item) => item.type === "desserts")
          .sort((a, b) => a.name.localeCompare(b.name));
        const fillingFlavours = flavourList
          .filter((item) => item.type === "fillings")
          .sort((a, b) => a.name.localeCompare(b.name));
        const buttercreamFlavour = flavourList
          .filter((item) => item.type === "buttercreams")
          .sort((a, b) => a.name.localeCompare(b.name));

        // Set the state with the filtered flavour lists
        setWeddingCakeFlavours(wCakeFlavours);
        setFillingFlavours(fillingFlavours);
        setButtercreamFlavour(buttercreamFlavour);
        setDessertFlavours(dessertFlavours);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCakes();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Discover Our Delicious Flavours | Cake Layers</title>
        <meta
          name="description"
          content="Explore the wide range of delicious flavours offered by Cake Layers. From wedding cakes to cupcakes and desserts, find the perfect taste for your celebration."
        />
        <meta
          name="keywords"
          content="cake flavours, wedding cakes, cake ladoo, celebration cakes, cupcakes, desserts, Cake Layers, toppings"
        />
        <meta
          property="og:title"
          content="Discover Our Delicious Flavous | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore the wide range of delicious flavours offered by Cake Layers. From wedding cakes to cupcakes and desserts, find the perfect taste for your celebration."
        />
        <meta property="og:url" content="https://cakelayers.web.app/flavours" />{" "}
        {/* Update with your actual URL */}
        <meta property="og:type" content="website" />
        {/* Optionally include an image if you have one */}
        {/* <meta property="og:image" content="https://example.com/path/to/image.jpg" /> */}
      </Helmet>
      <section id="flavour_section">
        <div className="flavour_img_container">
          <div className=" flavour_hero_txt text_container">
            <h1>
              Cherished Classics of the West
              <br />
              Warm Spices of the East
            </h1>
            <p id="hero_paragraph">
              {/* "Flavours that inspire, creations that delight.
              <br />
              Your celebration deserves a taste as unforgettable as the moment
              itself" */}
              I am proud to offer all cake flavours in traditional, egg-free,
              vegan and (some) gluten-free sponge. From classic favourites to
              flavours inspired by my South Asian heritage, each recipe is
              carefully crafted without the use of artificial flavours, allowing
              the natural essence of the ingredients to shine.
            </p>
          </div>
        </div>
        <div className="disclaimer-container text_container">
          <h2>Allergen Disclaimer</h2>
          <p>
            While I take every precaution for safety and cleanliness in the
            kitchen, I cannot guarantee a completely wheat, nut, or dairy-free
            environment. If you have specific allergies or dietary restrictions,
            please exercise caution and consult with me for more information.
          </p>
        </div>
        <div className="flavour_container">
          <div className="flavour_content">
            <div className="flavour_image">
              <img
                src={flavourWeddingCakeImg}
                alt="A stunning five-tier white cake displayed on a round table in a beautifully decorated party hall."
              />
            </div>
            <div className="flavour_txt text_container">
              <h2>Cakes</h2>
              <div className="flavour_grid">
                {weddingFlavours.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
              <br />
              <a id="fillings_buttercreams_link" href="#fillings_buttercreams">
                View Fillings & Buttercreams
              </a>
            </div>
          </div>
          <div className="flavour_content">
            <div className="flavour_image ladoo_flavours">
              <img
                src={flavourLadooImg}
                alt="A large assortment of ladoos showcasing nine different flavours, all beautifully aligned."
              />
              <img src={flavourToppingImg} />
            </div>

            <div className="flavour_txt text_container">
              <h2>Cake Ladoo</h2>
              <div className="flavour_grid">
                {flavours.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
              <h2 id="topping_title">Toppings</h2>
              <div className="flavour_grid">
                {toppings.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
            </div>
          </div>

          <div className="flavour_content">
            <div className="flavour_image">
              <img
                src={flavourDessertImg}
                alt="A beautiful golden tray adorned with white macarons and four elegantly decorated cupcakes."
              />
            </div>
            <div className="flavour_txt text_container">
              <h2>Cupcakes and Desserts </h2>
              <div className="flavour_grid">
                {dessertFlavours.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
              <br />
              <h2>Macarons</h2>
              <p>
                Classic macarons with a variety of fillings to choose from. See
                options below:
              </p>
              <a id="fillings_buttercreams_link" href="#fillings_buttercreams">
                View Fillings & Buttercreams
              </a>
            </div>
          </div>

          <div
            id="fillings_buttercreams"
            className="flavour_content fillings_buttercreams"
          >
            <div className="flavour_txt text_container fillings_buttercreams_content">
              <h2>Fillings</h2>
              <div className="flavour_grid">
                {fillingFlavours.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
            </div>
            <div className="flavour_txt text_container fillings_buttercreams_content">
              <h2>Buttercreams</h2>
              <div className="flavour_grid">
                {buttercreamFlavour.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
}
