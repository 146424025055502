import { useEffect, useState } from "react";
import { useCart } from "../../context/CartContext";
import "./ContactStyle.css";
import { Container, Row, Col } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"; // Regular icons
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Contact() {
  const { getCartData } = useCart();

  const [ladooItems, setLadooItems] = useState([]);
  const [galleryItems, setGalleryItems] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  useEffect(() => {
    const cartItems = getCartData();
    const ladoos = cartItems.filter((item) => item.type === "ladooBox");
    const galleryImages = cartItems.filter(
      (item) => item.type === "galleryImage"
    );
    setLadooItems(ladoos);
    setGalleryItems(galleryImages);
  }, [getCartData]);

  const onChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (event, formType) => {
    //recaptcha
    event.preventDefault();

    if (!recaptchaToken) {
      toast.error("Please complete the reCAPTCHA.");
      return;
    }

    const response = await fetch(
      "https://us-central1-cake-layers-2024-i549o.cloudfunctions.net/verifyRecaptcha",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: recaptchaToken }),
      }
    );

    const result = await response.json();

    if (result.success) {
      const form = event.target;
      const formData = new FormData(form);

      const formattedLadooItems = ladooItems
        .map((ladooBox, index) => {
          if (Array.isArray(ladooBox.items)) {
            return (
              `Ladoo Box ${index + 1} (${ladooBox.items.length} items):\n` +
              ladooBox.items
                .map(
                  (ladoo) =>
                    `Flavour: ${ladoo.flavour?.name || "Unknown"}, Topping: ${
                      ladoo.topping?.name || "None"
                    }`
                )
                .join("\n")
            );
          } else {
            return `Error: Ladoo Box ${
              index + 1
            } does not have a valid items array.`;
          }
        })
        .join("\n\n");

      const formattedGalleryItems = galleryItems
        .map((galleryItem, index) => {
          return `Gallery Item ${index + 1}: name: ${
            galleryItem.items?.name || "N/A"
          }, image: ${galleryItem.items?.image || "N/A"}`;
        })
        .join("\n\n");

      const templateParams = {
        name: formData.get("name"),
        number: formData.get("number"),
        email: formData.get("email"),
        eventDate: formData.get("event-date") || "",
        guests: formData.get("number-of-guests") || "",
        venue: formData.get("venue") || "",
        tiers: formData.get("number-of-tiers") || "",
        flavours: formData.get("number-of-flavours") || "",
        message: formData.get("message"),
        ladooCart: formattedLadooItems,
        galleryCart: formattedGalleryItems,
      };

      try {
        if (formType === "general") {
          await emailjs.send(
            "service_gt1oi8m",
            "template_nuwwsbd",
            templateParams,
            "zmD5Y-2Ry4yb1wbAP"
          );

          toast.success("General enquiry send Successfully!");
        } else if (formType === "wedding") {
          await emailjs.send(
            "service_gt1oi8m",
            "template_im2good",
            templateParams,
            "zmD5Y-2Ry4yb1wbAP"
          );
          toast.success("Wedding cake enquiry sent successfully!");
        }
        form.reset();
      } catch (error) {
        console.error("Form submission error:", error);
      }
    } else {
      console.log("Recaptcha verification failed:", result.error);
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact Us | Cake Layers</title>
        <meta
          name="description"
          content="Get in touch with Cake Layers for inquiries, orders, or more information. Our team is here to assist you with all your cake and dessert needs."
        />
        <meta
          name="keywords"
          content="contact us, cake inquiries, order cakes, customer support, Cake Layers contact, contact"
        />
        <meta property="og:title" content="Contact Us | Cake Layers" />
        <meta
          property="og:description"
          content="Get in touch with Cake Layers for inquiries, orders, or more information. Our team is here to assist you with all your cake and dessert needs."
        />
        <meta
          property="og:url"
          content="https://cakelayers.web.app/contact-us"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="contact_title_outr">
          <div className="contact_title_sub text_container">
            <h1>Get In Touch</h1>
            <p>
              I would love to hear from you! Whether you have questions, want to
              place an order, or
              <br />
              need more information, feel free to reach out. I'm here to assist
              you and
              <br />
              ensure your experience is as sweet as my cakes.
            </p>
          </div>
          <div className="cont_container">
            <div className="contact_item">
              <div className="icon_box">
                <FontAwesomeIcon icon={faEnvelope} /> {/* Email Icon */}
              </div>
              <div className="cont_details">
                <h3>Email</h3>
                <p>purmilla@cakelayers.co.uk</p>
              </div>
            </div>
            <div className="contact_item">
              <div className="icon_box">
                <FontAwesomeIcon icon={faPhone} /> {/* Phone Icon */}
              </div>
              <div className="cont_details">
                <h3>Call</h3>
                <p>07904 966314</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="outer_contact_container">
          <div className="inner_contact_container_text text_container">
            <h2>GENERAL ENQUIRY</h2>
            <p>
              Please use this form for general inquiries. Whether you have a
              question, need information, or wish to contact us for anything
              other than a wedding cake, you’re in the right place to start.
            </p>
          </div>
          <div className="inner_contact_container_form">
            <Container id="container">
              <Row className="sec_sp">
                <Col lg="9" className="d-flex align-items-center">
                  <form
                    className="contact__form w-100"
                    onSubmit={(e) => handleSubmit(e, "general")}
                  >
                    <Row>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Name"
                          type="text"
                          required
                        />
                      </Col>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="number"
                          name="number"
                          placeholder="Phone Number"
                          type="text"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          type="email"
                          required
                        />
                      </Col>
                    </Row>
                    <textarea
                      className="form-control rounded-0"
                      id="message"
                      name="message"
                      placeholder="Message"
                      rows="5"
                      required
                    ></textarea>
                    <br />
                    <Row className="btn_captcha_row">
                      <Col lg="12" className="form-group">
                        <ReCAPTCHA
                          sitekey="6Lf1fkUqAAAAAIdgQqF2m3nbSkFfnpVTEBRsWFQO" // Replace with your actual site key
                          onChange={onChange}
                        />
                        <button className="btn ac_btn" type="submit">
                          SEND
                        </button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
      <section>
        <div className="outer_contact_container">
          <div className="inner_contact_container_text text_container">
            <h2>WEDDING ENQUIRY</h2>
            <p>
              For all wedding-related inquiries, including cake designs, event
              details, and quotes, please use this form. We are here to help
              make your special day perfect!
            </p>
          </div>
          <div className="inner_contact_container_form">
            <Container id="container">
              <Row className="sec_sp">
                <Col lg="9" className="d-flex align-items-center">
                  <form
                    className="contact__form w-100"
                    onSubmit={(e) => handleSubmit(e, "wedding")}
                  >
                    <Row>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Name"
                          type="text"
                          required
                        />
                      </Col>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="number"
                          name="number"
                          placeholder="Phone Number"
                          type="text"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          type="email"
                          required
                        />
                      </Col>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="event-date"
                          name="event-date"
                          placeholder="Event Date"
                          type="date"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="number-of-guests"
                          name="number-of-guests"
                          placeholder="Number of Guests"
                          type="number"
                          required
                        />
                      </Col>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="venue"
                          name="venue"
                          placeholder="Venue"
                          type="text"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="number-of-tiers"
                          name="number-of-tiers"
                          placeholder="Number of Tiers"
                          type="number"
                          required
                        />
                      </Col>
                      <Col lg="6" className="form-group">
                        <input
                          className="form-control"
                          id="number-of-flavours"
                          name="number-of-flavours"
                          placeholder="Number of Flavours"
                          type="number"
                          required
                        />
                      </Col>
                    </Row>
                    <textarea
                      className="form-control rounded-0"
                      id="message"
                      name="message"
                      placeholder="Message"
                      rows="5"
                      required
                    ></textarea>
                    <br />
                    <Row className="btn_captcha_row">
                      <Col lg="12" className="form-group">
                        <ReCAPTCHA
                          sitekey="6Lf1fkUqAAAAAIdgQqF2m3nbSkFfnpVTEBRsWFQO" // Replace with your actual site key
                          onChange={onChange}
                        />
                        <button className="btn ac_btn" type="submit">
                          SEND
                        </button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
}
